import React, {useState} from 'react';
import {Grid, Button} from "@mui/material";
import {Link} from 'react-router-dom';
import Question from "../components/Question";
import { useSelector, useDispatch } from 'react-redux';
import { selectQuestions, upsertQS} from "../app/reducers/questions";
import {changeName, selectCat} from "../app/reducers/cat";
import questionMap from '../app/configs/questions';
import './pages.css'

let questionList = [
    {Q: 'At what level are we creating this estimate for?', C:['Commercial', 'Federal', 'State'], A:'', type: 'pick_one'},
    // {Q: 'This is my forth Quesiton, fill in the blank?', I: 'text' ,A: ''}
];

export default function Questionnaire(){
    const [level, setLevel] = useState();
    const [current, setCurrent] = useState(0);
    const [complete, setComplete] =  useState(false);
    const [display, setDisplay] = useState();
    const dispatch = useDispatch();

    function handleTrigger(answer) {
        questionList[current].A = answer;
        if (current === 0) {
            questionList = questionList.concat(questionMap[answer]);
            setLevel(answer);
        } else if (answer instanceof Array) {
            let tempArry = [];
            answer.map(I => {
                if (questionMap[I] instanceof Array) {
                    tempArry = tempArry.concat(questionMap[I].map(S => {
                        return S
                    }));
                } else {
                    tempArry.push(questionMap[I])
                }
            });
            questionList = questionList.concat(tempArry);
        }
        if (current + 1 === questionList.length) {
            setComplete(true)
        } else {
            setCurrent(current + 1)
        }
    }

    function finishQA(){
        // Sending raw questionList results in one big unfiltered table
        dispatch(upsertQS(questionList));
        dispatch(changeName('Gus'));
        let nav = document.getElementById('btn-action');
        nav.click()
    }

    return(
        <Grid container>
            <div className={'header'}></div>
            <Grid item xs={3}>
                <div className={'navBox'}></div>
            </Grid>
            <Grid item xs={9}>
                <div className={'header'}></div>
                {complete ? <div><h1>You have finished the questionnaire</h1><Grid container><Grid item xs={3}></Grid><Grid item xs={4}><Button className={'btn-center'} variant={'outlined'} size={'large'} onClick={finishQA}>Complete</Button><Link id={'btn-action'} to={'/'} hidden={true}></Link></Grid><Grid item xs={5}></Grid></Grid></div> : <Question question={questionList[current]} trigger={(e)=>{handleTrigger(e)}}/>}
            </Grid>
        </Grid>
    )
}
