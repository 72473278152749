import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

const initialState = {
    value: [],
    status: 'idle',
};

export const questionSlice = createSlice({
    name: 'questions',
    initialState,
    reducers:{
        upsertQS: (state, action) => {
            state.value = action.payload;
        }
    }
});

export const { upsertQS } = questionSlice.actions;
export const selectQuestions = (state) => state.questions.value;

export default questionSlice.reducer;
