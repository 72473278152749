import React, {useEffect, useState} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {Grid, Tooltip, Zoom} from "@mui/material";
import { AddCircleOutline, Apps, Create, FindInPage, ManageSearch, ContactPhone } from "@mui/icons-material"
import Landing from "./pages/Landing";
import Login from "./pages/Login";
import Questionnaire from "./pages/Questionnaire";
import Nav from "./components/Nav";
import './global.css'
import {Counter} from "./features/counter/Counter";
import Estimate from "./pages/Estimate";
import Archive from "./pages/Archive";

function Main() {
  return(
      <div>
        <Nav/>
        <Router>
          <Grid container>
            <Grid item xs={1} className={'main-left sidebar_background'}>
              <br/>
              <Tooltip title={'Back to Dashboard'} TransitionComponent={Zoom} arrow light followCursor>
                <Link to={'/'} className={'side-bar-links'}><Apps fontSize={'inherit'}/></Link>
              </Tooltip>
              <br/>
              <br/>
              <Tooltip title={'Create new estimate'} TransitionComponent={Zoom} arrow light followCursor>
                <Link to={'/questionnaire'} className={'side-bar-links'}><AddCircleOutline fontSize={'inherit'}/></Link>
              </Tooltip>
              <br/>
              <br/>
              <Tooltip title={'Edit estimate'} TransitionComponent={Zoom} arrow light followCursor>
                <Link to={'/estimates'} className={'side-bar-links'}><Create fontSize={'inherit'}/></Link>
              </Tooltip>
              <br/>
              <br/>
              <Tooltip title={'Research Tickets'} TransitionComponent={Zoom} arrow light followCursor>
                <Link to={'/archive'} className={'side-bar-links'}><FindInPage fontSize={'inherit'}/></Link>
              </Tooltip>
              <br/>
              <br/>
              <Tooltip title={'Estimate Archive'} TransitionComponent={Zoom} arrow light followCursor>
                <Link to={'/archive'} className={'side-bar-links'}><ManageSearch fontSize={'inherit'}/></Link>
              </Tooltip>
              <br/>
              <br/>
              <Tooltip title={'Communications'} TransitionComponent={Zoom} arrow light followCursor>
                <Link to={'/archive'} className={'side-bar-links'}><ContactPhone fontSize={'inherit'}/></Link>
              </Tooltip>
              <br/>
            </Grid>
            <Grid item xs={11} className={'main-center'}>
              <Switch>
                <Route path={'/counter'}>
                  <Counter/>
                </Route>
                <Route path={'/questionnaire'}>
                  <Questionnaire/>
                </Route>
                <Route path={'/estimates'}>
                  <Estimate/>
                </Route>
                <Route path={'/archive'}>
                  <Archive/>
                </Route>
                <Route path={'/'}>
                  <Landing/>
                </Route>
              </Switch>
            </Grid>
          </Grid>
        </Router>
      </div>
  )
}

function App() {
  const [hide, setHide] = useState();

  useEffect(()=>{
    // console.log(window.location.pathname);
    if(window.location.pathname === '/login'){
      console.log('yes, hidden');
      setHide(true)
    }else{
      setHide(false)
    }
  },[]);
  return (
      hide ? <Login/> : <Main/>
  );
}

export default App;
