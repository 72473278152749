import React from 'react';
import {Grid, Tooltip, Zoom} from "@mui/material";
import Square from "../components/Square";
import {AddCircleOutline, Folder, Inventory2, Settings} from "@mui/icons-material";
import {Link} from "react-router-dom";

export default function Landing(){
    return(
        <div>
            <Grid containter>
                <Grid item xs={12}>
                    <div className={'header-squares'}>Start Here
                    <Grid container style={{paddingTop: '10px', width: '100%'}}>
                        <Grid item xs={3}>
                            <Tooltip title={'Create new estimate'} TransitionComponent={Zoom} arrow light followCursor>
                                <Link to={'/'} className={'main-bar-links'}><AddCircleOutline fontSize={'inherit'}/></Link>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={3}>
                            <Tooltip title={'Active Estimates'} TransitionComponent={Zoom} arrow light followCursor>
                                <Link to={'/'} className={'main-bar-links'}><Folder fontSize={'inherit'}/></Link>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={3}>
                            <Tooltip title={'Estimate Archive'} TransitionComponent={Zoom} arrow light followCursor>
                                <Link to={'/'} className={'main-bar-links'}><Inventory2 fontSize={'inherit'}/></Link>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={3}>
                            <Tooltip title={'Settings'} TransitionComponent={Zoom} arrow light followCursor>
                                <Link to={'/'} className={'main-bar-links'}><Settings fontSize={'inherit'}/></Link>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    </div>
                </Grid>
            </Grid>
            <h2>Recent Estimates</h2>
            <Grid container>
                <Grid item xs={3}>
                    <Square title={'Active Estimates'} discription={'disc here'} href={'/estimates'}/>
                </Grid>
                <Grid item xs={3}>
                    <Square title={'Research Tickets'} discription={'disc here'} href={'/'}/>
                </Grid>
                <Grid item xs={3}>
                    <Square title={'Estimate Profiles'} discription={'disc here'} href={'/'}/>
                </Grid>
                <Grid item xs={3}>
                    <Square title={'Communications'} discription={'disc here'} href={'/'}/>
                </Grid>
            </Grid>
        </div>
    )
}
