// Main questions config, maps to all other question groups

const questionMap = {
    // main branches
    Commercial:[
        {Q: 'What would you like to name this estimate?', A: '', type:'input', T: 'General'},
        {Q: 'How large is the organization?', C:['Small', 'Medium', 'Large'], A: '', type:'pick_one', T: 'General'},
        {Q: 'What is the organization’s estimated budget for this project?', A: '', type:'input#', E: '$', T: 'General'},
        {Q: 'Does the client have a dedicated PM who would run this project?', A: '', T: 'General'},
        {Q: 'Does the client have an IT team that would be supporting this?', A: '', T: 'General'},
        {Q: 'What are the core elements to be included in the estimate?\n (these should be the core services that the user initially set up)', C:['Discovery', 'Dashboards', 'Data Science Algorithm', 'Web Application', 'Data Warehouse'], A: '', type: 'multiple_choice'}
    ],
    Federal:[],
    State:[],

    // Core elements
    'Discovery': [
        {Q: 'Are you ready to explore your discovery options?', A: ''},
        {Q: 'Can you identify how many unknowns there are?', A: '', T: 'Discovery'}
    ],
    'Dashboards': [
        {Q: 'Are you ready to explore your dashboard options?', A: ''}
    ],
    'Data Science Algorithm': [
        {Q: 'Are you ready to explore your analysis options?', A: ''}
    ],
    'Web Application': [
        {Q: 'Are you able to explore your application options?', A: ''},
        {Q: 'How many users do you estimate will utilize this application?', A: '', type:'input#', E: '', T: 'Webapp'}
    ],
    'Data Warehouse': [
        {Q: 'Are you ready to explore your data options?', A: ''}
    ]
};

export default questionMap;
