import React from 'react';
import {Grid, Icon, IconButton, Tooltip} from "@mui/material";
import { Person, AssignmentInd, AccountCircle } from '@mui/icons-material';
import './components.css';
import '../global.css';

export default function Nav(){
    function profileClick(){
        if(window.confirm('Are you sure you would like to logout?')){
            console.log('logged out')
        }
    }

    return(
        <Grid container className={'nav_bar nav_background'}>
            <Grid item xs={4}></Grid>
            <Grid item xs={4} className={'title'}><h1>Estamigo</h1></Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={1} className={'user-info nav-bar-links'} onClick={profileClick}>
                <Tooltip title={'Kyle Thomas'}>
                    <AssignmentInd fontSize={'large'}/>
                </Tooltip>
            </Grid>
        </Grid>
    )
}
