import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import questionsReducer from './reducers/questions';
import catReducer from '../app/reducers/cat';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    questions: questionsReducer,
    cat: catReducer
  },
});
