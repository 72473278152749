import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {selectQuestions} from '../app/reducers/questions';
import {Table, TableBody, TableCell, TableHead, TableRow, TableContainer, tableCellClasses, styled, Paper, Collapse, Button, Modal, Box, FormControl, RadioGroup, FormControlLabel, Switch, TextField} from "@mui/material";
import {AddCircleOutline, RemoveCircleOutline, EditOutlined} from "@mui/icons-material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function Estimate(){
    const questions = useSelector(selectQuestions);
    const [tableOpen, setTableOpen] = useState({General: false, Discovery: false, Webapp: false});
    const [rows, setRows] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState();
    const [editSelected, setEditSelected] =useState({answer: '', question: ''});

    function handleModalClose(){
        setIsModalOpen(false)
    }

    function openQuestionEditor(answer, question){
        setEditSelected({
            answer: answer,
            question: question
        });
        setIsModalOpen(true)
    }

    function handleTableChange(event){
        switch(event.target.dataset.id){
            case 'General':
                setTableOpen({...tableOpen, General: !tableOpen.General});
                break;
            case 'Discovery':
                setTableOpen({...tableOpen, Discovery: !tableOpen.Discovery});
                break;
            case 'Webapp':
                setTableOpen({...tableOpen, Webapp: !tableOpen.Webapp});
                break;
            default:
                console.log('Something is wrong: ', tableOpen);
                break;
        }
        // setTableOpen(!tableOpen)
    }

    useEffect(()=>{
        let tempRows = [];
        const general = (questions.filter(R=>{
            return R.T === 'General'
        }));
        if(general.length > 0){
            tempRows.push(general)
        }
        const discovery = (questions.filter(R=>{
            return R.T === 'Discovery'
        }));
        if(discovery.length > 0){
            tempRows.push(discovery)
        }
        const webapp = (questions.filter(R=>{
            return R.T === 'Webapp'
        }));
        if(webapp.length > 0){
            tempRows.push(webapp)
        }
        setRows(tempRows)
    },[]);

    function TableSection(props){
        return(
            <div className={tableOpen[props.info[0].T] ? 'spacing' : ''}>
                <h4 id={props.info[0].T + 'Opener'} data-id={props.info[0].T} hidden={tableOpen[props.info[0].T]} onClick={handleTableChange}><AddCircleOutline data-id={props.info[0].T} fontSize={'inherit'}/> {props.info[0].T} Questions</h4>
                <TableContainer component={Paper}>
                    <Collapse id={props.info[0].T + 'Table'} in={tableOpen[props.info[0].T]}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead data-id={props.info[0].T} onClick={handleTableChange}>
                                <StyledTableRow>
                                    <StyledTableCell data-id={props.info[0].T}><RemoveCircleOutline data-id={props.info[0].T} fontSize={'inherit'}/> {props.info[0].T} Questions</StyledTableCell>
                                    <StyledTableCell align="right">Answer</StyledTableCell>
                                    <StyledTableCell align="right"></StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {props.info.map((row)=>(
                                    <StyledTableRow key={row.Q}>
                                        <StyledTableCell style={{width: '100%'}}>{row.Q}</StyledTableCell>
                                        <StyledTableCell align={'right'}>{row.A}</StyledTableCell>
                                        <StyledTableCell align={'right'}>
                                            <Button onClick={() => openQuestionEditor(row.A, row.Q)}><EditOutlined/></Button>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableContainer>
            </div>
        )
    }

    function modalSelector(answer){
        if(/^\d+$/.test(answer)){
            answer = 'number'
        }
        if(answer.split('')[0] === '$'){
            answer = 'currency'
        }
        switch(answer){
            case 'Yes':
                return (
                    <FormControl>
                        <RadioGroup>
                            <FormControlLabel value={editSelected.answer === 'Yes' ? true : false} control={<Switch defaultChecked={editSelected.answer === 'Yes' ? true : false}/>} label={'True/False'}/>
                        </RadioGroup>
                    </FormControl>
                );
            case 'No':
                return (
                    <FormControl>
                        <RadioGroup>
                            <FormControlLabel value={editSelected.answer === 'Yes' ? true : false} control={<Switch defaultChecked={editSelected.answer === 'Yes' ? true : false}/>} label={'True/False'}/>
                        </RadioGroup>
                    </FormControl>
                );
            case 'number':
                return <TextField placeholder={'number'}></TextField>
            case 'currency':
                return <TextField placeholder={'currency'}></TextField>
            default:
                return <TextField placeholder={'text'}></TextField>
        }
    }

    return(
        <div className={'table'}>
            <h2>Active Estimates</h2>
            {rows.map(S=>{
                return <TableSection info={S}/>
            })}
            <Modal
                open={isModalOpen}
                onClose={handleModalClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box className={'question-edit-modal'} paper>
                    <h3>{editSelected.question}</h3>
                    {modalSelector(editSelected.answer)}
                    <p>This is my modal, yeah</p>
                    <Button onClick={handleModalClose}>Close</Button>
                </Box>
            </Modal>
        </div>
    )
}
