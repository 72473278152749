import React, {useState} from 'react';
import {getUser} from '../app/API';
import {Grid, Button, TextField} from "@mui/material";
import './pages.css'

export default function Login(){
    const [name, setName] = useState();
    const [password, setPassword] = useState();

    function logUserIn(){
        getUser(name, password).then(ret=>{
            console.log(ret);
            window.location.replace('/')
        })
    }
    return (
        <Grid container className={'login-container'}>
            <div className={'login-spacer'}>v</div>
            <Grid xs={4}></Grid>
            <Grid xs={4} textAlign={'center'}>
                <TextField label={'User Name'} variant={'standard'} onChange={(e)=>{setName(e.target.value)}} value={name} size={'large'} fullWidth></TextField>
                <br/>
                <TextField label={'Password'} variant={'standard'} onChange={(e)=>{setPassword(e.target.value)}} value={password} size={'large'} fullWidth type={'password'}></TextField>
                <br/>
                <br/>
                <Button onClick={logUserIn} variant={'outlined'} size={'large'}>Login</Button>
            </Grid>
            <Grid xs={4}></Grid>
        </Grid>
    )
}
