import React from 'react';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import {Link} from "react-router-dom";

export default function Square(props){
    function activateLink(){
        let nav = document.getElementById(props.title);
        nav.click()
    }
    return(
        <div className='landing-squares' onClick={activateLink}>
            <div className={'landing-icon'}>
                <AddPhotoAlternateIcon fontSize={'inherit'} className={'icon-main'}/>
            </div>
            <br/>
            {props.title}
            <br/>
            {props.discription}
            <Link id={props.title} to={props.href} hidden={true}/>
        </div>
    )
}
