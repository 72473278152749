import React, {useEffect, useState} from 'react';
import {Button, Grid, TextField, FormControl, FormGroup, FormControlLabel, RadioGroup, Radio, Checkbox, InputAdornment} from "@mui/material";

export default function Question(props){
    const [value, setValue] = useState(props.question.C ? props.question.C[0] : '');
    const [dollar, setDollar] = useState('');
    const [text, setText] = useState('');

    function answer(A){
        props.trigger(A)
    }

    function constructMultipleChoice(event){
        event.preventDefault();
        if(value instanceof Array) {
            if (value.find(I => I === event.target.value)) {
                setValue(value.filter(D => {
                    return D !== event.target.value
                }))
            } else {
                setValue(value.concat([event.target.value]))
            }
        }else{
            setValue([event.target.value])
        }
    }

    function handleSelection(event){
        event.preventDefault();
        props.trigger(value)
    }

    function questionType(question){
        switch(question.type) {
            case 'input':
                return (
                    <div>
                        <TextField
                            data-embelishment={question.E}
                            onKeyPress={textEntry}
                            value={text}
                            onChange={(e)=>{setText(e.target.value)}}
                        />
                        <br/>
                        <Button sx={{ mt: 1, mr: 1 }} variant="outlined" display={'inline-flex'}>
                            Back
                        </Button>
                        <Button sx={{ mt: 1, mr: 1 }} onClick={submitText} variant="outlined" display={'inline-flex'}>
                            Next
                        </Button>
                    </div>
                );
            case 'input#':
                return (
                    <div>
                        <TextField
                            data-embelishment={question.E}
                            onKeyPress={textEntry}
                            value={dollar}
                            onChange={numberWithCommas}
                            InputProps={{
                                startAdornment: question.E === '$' ? <InputAdornment position="start">$</InputAdornment> : '',
                            }}
                        />
                        <br/>
                        <Button sx={{ mt: 1, mr: 1 }} variant="outlined" display={'inline-flex'}>
                            Back
                        </Button>
                        <Button data-embelishment={question.E} sx={{ mt: 1, mr: 1 }} onClick={submit} variant="outlined" display={'inline-flex'}>
                            Next
                        </Button>
                    </div>
                );
            case 'pick_one':
                return (
                    <form onSubmit={handleSelection}>
                        <FormControl component="fieldset">
                            {/*<FormLabel component="legend">Gender</FormLabel>*/}
                            <RadioGroup
                                aria-label="gender"
                                defaultValue={question.C[0]}
                                name="radio-buttons-group"
                                onChange={(event)=>{setValue(event.target.value)}}
                            >
                                {question.C.map((response)=>{return <FormControlLabel value={response} control={<Radio />} label={response} />})}
                            </RadioGroup>
                            <Grid row>
                            <Button sx={{ mt: 1, mr: 1 }} variant="outlined" display={'inline-flex'}>
                                Back
                            </Button>
                            <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined" display={'inline-flex'}>
                                Next
                            </Button>
                            </Grid>
                        </FormControl>
                    </form>
                );
            case 'multiple_choice':
                return (
                    <form onSubmit={handleSelection}>
                        <FormControl component="fieldset">
                            <FormGroup onChange={constructMultipleChoice}>
                                {question.C.map((response)=>{return <FormControlLabel value={response} control={<Checkbox />} label={response} />})}
                            </FormGroup>
                            <Grid>
                            <Button sx={{ mt: 1, mr: 1 }} variant="outlined" display={'inline-flex'}>
                                Back
                            </Button>
                            <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined" display={'inline-flex'}>
                                Next
                            </Button>
                            </Grid>
                        </FormControl>
                    </form>
                );
            default:
                return (<Buttons/>)
        }
    }

    function Buttons(){
        return(
            <div>
                <Button variant={'outline'} color={'success'} onClick={()=>answer('Yes')}>Yes</Button>
                <Button variant={'outline'} color={'error'} onClick={()=>answer('No')}>No</Button>
            </div>
        )
    }

    function textEntry(event){
        console.log(event.target);
        if(event.key === 'Enter'){
            if(props.question.type === 'input#'){
                submit(event)
            }else{
                submitText()
            }
        }
    }
    function submit(event){
        props.trigger(event.target.dataset.embelishment + dollar);
        setDollar('')
    }
    function submitText(){
        props.trigger(text);
        setText('')
    }
    function numberWithCommas(event) {
        const n = parseInt(event.target.value.replace(/\D/g, ''));
        setDollar((Math.round(n * 100) / 100).toLocaleString() === 'NaN' ? '0' : (Math.round(n * 100) / 100).toLocaleString());
    }
    return(
        <div>
            <h3>{props.question.Q}</h3>
            {questionType(props.question)}
        </div>
    )
}
